<template>
  <v-chart
    class="chart"
    :option="option"
  />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { GridComponent } from "echarts/components";
import { BarChart } from "echarts/charts";
import { PieChart, xAxis } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  BarChart,
  GridComponent,
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          diqu:"",
          main:[
            
          ]
        }
      },
    },
  },
  watch:{
    data(nv,ov){
      // console.log('nv',nv)
      this.option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          bottom: "3",
          left: "center",
        },
        title: {
          text: this.data.diqu,
          left: "center",
          top: 3,
          textStyle: {
            color: "#333",
          },
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            
            data: this.data.main,
          },
        ],
      }
    }
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: "item",
        },
        legend: {
          bottom: "3",
          left: "center",
        },
        title: {
          text: this.data.diqu,
          left: "center",
         
          top: 3,
          textStyle: {
            color: "#333",
            fontSize:"13",
          },
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            
            data: this.data.main,
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.chart {
  height: 200px;
}
</style>