<template>
  <div class="citypopulation">
    <breadcrumb icon="el-icon-s-grid" name="城市区位"></breadcrumb>
    <div v-if="nodata">
      没有数据
    </div>
    <div v-else class="content-population">
      <div class="titleBox">
        <h2 class="title">{{  PopulationList.title  }}</h2>
        <el-select size="mini" class="selectbox" v-model="c_year" @change="changeYear" placeholder="请选择">
          <el-option v-for="item in yearList" :key="item.id" :label="item.year" :value="item.id">
          </el-option>
        </el-select>
        <div class="btnbox">
          <download-button type="excel" :params="{
            goods_id: 4,
            condition: { city_plate_id: city_plate_id, year: c_year }
          }" text="导出Excel"></download-button>
        </div>
      </div>
      <el-row :gutter="20">
        <el-col :span="4">
          <div class="grid-content bg-purple">常住人口(万人)</div>
        </el-col>
        <el-col :span="7">
          <div class="grid-content bg-purple">人口性别构成占比(%)</div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">人口年龄构成(万人)</div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">人口年龄构成占比(%)</div>
        </el-col>
      </el-row>
      <div class="tablebox">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-table   ref="singleTable" :highlight-current-row="true" @row-click="clickRow" size="mini" height="260px"
              max-height="260px" :data="PopulationList.table">
              <el-table-column fixed class-name="left" prop="diqu" label="地区">
              </el-table-column>
              <el-table-column prop="changzhurenkou" class-name="right" label="常住人口">
              </el-table-column>
              <el-table-column prop="nan" class-name="left" label="男">
              </el-table-column>
              <el-table-column prop="nv" class-name="center" label="女">
              </el-table-column>
              <el-table-column prop="xingbiebi" class-name="right" label="性别比">
              </el-table-column>
              <el-table-column class-name="left" prop="sui014" label="0-14岁">
              </el-table-column>
              <el-table-column class-name="center" prop="sui1559" label="15-59岁">
              </el-table-column>
              <el-table-column class-name="right" prop="jia60" label="60岁以上">
              </el-table-column>
              <el-table-column prop="sui014p" class-name="left" label="0-14岁">
              </el-table-column>
              <el-table-column prop="sui1559p" class-name="center" label="15-59岁">
              </el-table-column>
              <el-table-column prop="jia60p" class-name="right" label="60岁以上">
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
      <div class="line"></div>

      <div class="report">
        <div class="column">
          <div class="charttitle">常住人口(万人)</div>
          <bar :datax="datax" :datay="datay"></bar>
        </div>
        <div class="pan">
          <div class="sex">
            <div class="charttitle">人口性别构成占比(%)</div>
            <pie :data="sexData" />
          </div>
          <div class="age">
            <div class="charttitle">人口年龄构成占比(%)</div>
            <pie :data="ageData" />
          </div>
        </div>

      </div>

      <div class="footerinfo">
        <time-source :data="{ time: PopulationList.uptime, source: PopulationList.source }"></time-source>
      </div>

    </div>
  </div>
</template>
<script>
import Breadcrumb from "../components/common/Breadcrumb.vue";
import { citypopulationYear, citypopulation } from "../api/cityresearch";
import bar from "../components/echarts/bar";
import pie from "../components/echarts/pie";
import TimeSource from '../components/cityreserch/TimeSource.vue';

import DownloadButton from "@/components/down/DownloadButton.vue"
export default {
  data() {
    return {
      nodata: false,
      c_year: 0, //当前选择年份的id
      yearList: [
        {
          id: 0,
          year: "",
        },
      ],
      PopulationList: {
        table: [],
      },
      currentRow: null,
    };
  },

  components: { Breadcrumb, bar, pie, TimeSource, DownloadButton },
  computed: {

    city_plate_id: {
      get() {
        return this.$store.state.city_plate_id
      },
      set(v) {
        this.$store.dispatch('changeCityPlateId', v);
      }
    },

    shi: {
      get() {
        return this.$store.state.shi;
      },
      set(shi) {
        this.$store.dispatch("changeShi", shi);
      },
    },
    //   datax bar x 分类 数组
    //   datay:[], //bar y 值 数组
    datax: {
      get() {
        return this.PopulationList.table.map((item) => {
          return item.diqu;
        });
      },
      set(v) {
        this.datax = v;
      },
    },
    datay: {
      get() {
        const y = this.PopulationList.table.map((item) => {
          return item.changzhurenkou;
        });
        // console.log("y", y);
        return y;
      },
      set(v) {
        this.datay = v;
      },
    },
    sexData: {
      get() {
        let row =
          this.currentRow != null
            ? this.currentRow
            : this.PopulationList.table[0];

        if (row != null) {
          let obj = {};
          obj.diqu = row.diqu;
          let main = [];
          main.push({ name: "男", value: row.nan });
          main.push({ name: "女", value: row.nv });
          obj.main = main;
          console.log('aaaa',obj)

          return obj;
        }
      },
      set(v) {
        this.sexData = v;
      },
    },
    ageData: {
      get() {
        let row =
          this.currentRow != null
            ? this.currentRow
            : this.PopulationList.table[0];

        if (row != null) {
          let obj = {};
          obj.diqu = row.diqu;
          let main = [];
          main.push({ name: "0-14岁", value: row.sui014 });
          main.push({ name: "15-59岁", value: row.sui1559 });
          main.push({ name: "60岁以上", value: row.jia60 });
          obj.main = main;
          this.setCurrent(row)
          return obj;
        }
      },
      set(v) {
        this.ageData = v;
      },
    },
  },
  watch: {
    shi(newv) {
      this.nodata = false
      this.shi = this.$store.state.shi;
      this.getCityPopulationYear();
      // this.getCityPopulation();
    },
  },
  mounted() {
    this.getCityPopulationYear()

  },
  methods: {

    //更改年份
    changeYear(year) {
      // console.log('year',year)
      // this.c_year = year;
      this.getCityPopulation();
    },

    //点击行事件
    clickRow(row, column, event) {
      this.currentRow = row;
    },
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },

    getCityPopulationYear() {
      citypopulationYear({ city_plate_id: this.shi }).then((res) => {
        console.log("res.code", res.code, "res.data", res.data)
        if (res.code === 1001) {
          this.nodata = true
          return false;
        } else {
          this.nodata = false
        }
        this.yearList = res.data;
        if (this.c_year === 0) {
          this.c_year = res.data[0].id
        }
        this.getCityPopulation();
      });
    },
    getCityPopulation() {
      if (!(this.shi && this.c_year)) {  // 不请求的条件
        return
      }
      citypopulation({ city_plate_id: this.shi, year: this.c_year }).then(
        (res) => {
          console.log("citypopulation", res.data);
          this.PopulationList = res.data;
        }
      );
    },
  },
};
</script>
<style lang="scss">
.citypopulation {
  width: 100%;
  //   height: calc(100vh - 80px);
  overflow: hidden;
  color: #333;

}

.grid-content {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}

.content-population {
  width: 96%;
  margin: 0 2%;

  //   height:auto;
  //   overflow-x: hidden;
  .tablebox {
    height: 180px;
    //  overflow-y: scroll;
    //  overflow-x: hidden;
  }

  .titleBox {
    display: flex;
    flex: row;
    justify-content: left;
    align-items: center;
    .btnbox{
      flex:1;
      display: flex;
      justify-content: flex-end;
    }

  }

  .selectbox {
    margin-left: 20px;
    width: 120px;
  }

  .left {
    padding-left: 30px;
  }

  .right {
    border-right: solid 2px #efefef;
  }

  .right:last-child {
    border-right: none;
  }

  .line {
    margin-top: 10px;
    border-top: solid 4px #efefef;
  }

  .report {
    width: 100%;
    display: flex;
    min-height: 200px;
    max-height: 260px;

    .charttitle {
      text-align: left;
      font-size: 16px;
      font-weight: bold;
      margin: 10px;
    }

    .column,
    .pan {
      width: 100%;
      //   border: solid 1px #ddd;
    }

    .pan {
      display: flex;

      .sex {

        box-sizing: border-box;
        position: relative;
        // background: red;
        width: 100%;
        box-sizing: border-box;

        &::after {
          position: absolute;
          top: 2em;
          height: calc(100% - 4em);
          left: 0;
          content: "";
          width: 0;
          border-left: solid #f0f3f8 4px;
        }
      }

      .age {
        box-sizing: border-box;
        position: relative;
        width: 100%;

        &::after {
          position: absolute;
          top: 2em;
          height: calc(100% - 4em);
          left: 0;
          content: "";
          width: 0;
          border-left: solid #f0f3f8 4px;
        }
      }

      .charttitle {
        font-size: 16px;
        margin: 10px;
        text-align: center;
        font-weight: bold;
      }
    }

  }

  .footerinfo {
    border-top: solid 4px #efefef;
    margin: 5px 0;
    padding-top: 0px;
    text-align: left;
  }


}
</style>