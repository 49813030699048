<template>
  <v-chart
    class="chart"
    :option="option"
  />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { GridComponent } from "echarts/components";
import { BarChart } from "echarts/charts";
import { PieChart, xAxis } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  BarChart,
  GridComponent,
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  name: "bar",
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  props: {
    datax: {
      type: Array,
      default: function () {
        return [];
      },
    },
    datay: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  watch: {
    datax(nv, ov) {
      this.option = {
        xAxis: {
          type: "category",
          data: nv,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: this.datay,
            type: "bar",
          },
        ],
      };
    },
    datay(nv, ov) {
      this.option = {
        xAxis: {
          type: "category",
          data: this.datax,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: nv,
            type: "bar",
          },
        ],
      };
    },
  },
  data() {
    return {
      option: {
        xAxis: {
          type: "category",
          data: this.datax,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: this.datay,
            type: "bar",
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.chart {
  height: 240px;
}
</style>